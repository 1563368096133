import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const NavigationToggle = () => {
  const location = useLocation();

  const isQueue = location.pathname === "/";

  if (location.pathname === '/tv') {
    return null;
  }

  return (
    <div style={{ padding: '10px', position: 'fixed', bottom: 0, width: '100%', textAlign: 'center', zIndex: 100  }}>
      {isQueue ? (
        <Link to="/add-song" style={{ textDecoration: 'none'}}>
          <Button variant="contained" color="primary">
            Lisää Jonoon
          </Button>
        </Link>
      ) : (
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            Näytä Karaokejono
          </Button>
        </Link>
      )}
    </div>
  );
};

export default NavigationToggle;
