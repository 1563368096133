import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const BASE_URL = 'https://karaoke-421621.ew.r.appspot.com';
// const BASE_URL = 'http://localhost:8080';

export const fetchSongs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/getSongs`);
        return response.data; 
    } catch (error) {
        console.error('Failed to fetch songs:', error);
        return [];
    }
};

export const fetchSettings = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/getSettings`);
        return response.data; 
    } catch (error) {
        console.error('Failed to fetch settings:', error);
        return [];
    }
};

export const addSong = async (songName, artist, singers, duration, canonical_url) => {
    const uniqueId = uuidv4();
    try {
        const response = await axios.post(`${BASE_URL}/addSong`, {
            songName,
            artist,
            singers,
            id: uniqueId,
            duration,
            canonicalUrl: canonical_url
        });
        if (response.data.success) {
            const existingSongs = JSON.parse(localStorage.getItem('userSongs')) || [];
            existingSongs.push(uniqueId);
            localStorage.setItem('userSongs', JSON.stringify(existingSongs)); 
            return true;
        }
        return false;
    } catch (error) {
        console.error('Failed to add song:', error);
        return false;
    }
};

export const markAsDone = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/markDone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return true;
      } else {
        throw new Error('Failed to mark as true');
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };
  
