import React, { useEffect } from 'react';

const Admin = () => {
  useEffect(() => {
    localStorage.setItem('hamiskaraoke', 'true');
  }, []);

  return (
    <div></div>
  );
};

export default Admin;
