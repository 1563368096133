import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Paper, Typography, List, ListItem, ListItemText, CircularProgress, ListItemAvatar, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { addSong, fetchSettings } from '../api/api';
import debounce from 'lodash.debounce';
import { throttle } from 'lodash';

const AddSongView = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [songs, setSongs] = useState([]);
  const [selectedSong, setSelectedSong] = useState(null);
  const [singerName, setSingerName] = useState('');
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const loadSettings = async () => {
      const fetchedSettings = await fetchSettings();
      setSettings(fetchedSettings);
    };

    loadSettings();
  }, []);

/*  useEffect(() => {
    const handleScroll = () => {
        const bottomOffset = 30;
        if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - bottomOffset || loading) return;
        setPage(prevPage => prevPage + 1);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
}, [loading]); 
*/

useEffect(() => {
    const handleScroll = throttle(() => {
        const threshold = 10; 
        const position = window.innerHeight + window.scrollY;
        const height = document.body.scrollHeight;
        if (position >= height - threshold && !loading) {
            setPage(prevPage => prevPage + 1);
        }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
        handleScroll.cancel(); 
    };
}, [loading]);

  const debouncedFetchSongs = useCallback(debounce((term, page) => {
    if (term) {
      setLoading(true);
      fetch(`https://api.singa.com/v1.4/search/?backend=recombee&market=FI&page=${page}&page_size=15&search=${encodeURIComponent(term)}&type=songs`)
        .then(response => response.json())
        .then(data => {
          if (data.songs && data.songs.items) {
            if (page === 1) {
              setSongs(data.songs.items);
            } else {
              setSongs(prevSongs => [...prevSongs, ...data.songs.items]);
            }
          }
          setLoading(false);
        });
    }
  }, 800), []);

  useEffect(() => {
    debouncedFetchSongs(searchTerm, page);
  }, [searchTerm, page, debouncedFetchSongs]);

  const handleSearchChange = (event) => {
    setLoading(true);
    setSearchTerm(event.target.value);
    setPage(1); 
    setSongs([]); 
  };
 
  const handleSingerChange = (event) => {
    setSingerName(event.target.value);
  };

  const artists = selectedSong ? selectedSong.artists.map((artist, index) => (index > 0 ? ", " + artist.name : artist.name)).join('') : '';

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!selectedSong || !singerName.trim()) {
      alert('Please select a song and enter the singer\'s name.');
      return;
    }
  
    try { 
      const success = await addSong(selectedSong.name, artists, singerName, selectedSong.variants[0].duration, selectedSong.canonical_url);
      if (success) {
        navigate('/');
        clearSelection();
      } else {
        throw new Error('Failed to add song due to server error.');
      }
    } catch (error) {
      alert(error.message || 'Failed to add song.');
    }
  };
  

  const clearSelection = () => {
    setSelectedSong(null);
    setSearchTerm('');
    setPage(1);
  };

  if (settings === null) {
    return (
      <Paper style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
         <CircularProgress />
        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>Ladataan...</Typography>
      </Paper>
    );
  }

  if (settings !== null && settings[0][0].includes('TRUE')) {
    return (
      <Paper style={{ margin: '20px', padding: '20px' }}>
        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>Jono on suljettu.</Typography>
      </Paper>
    );
  }

  return (
    <Paper style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
      <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>
        Lisää kappaleesi jonoon
      </Typography>

      <form onSubmit={handleSubmit}>
      {selectedSong ? (
        <>
      <Typography
        variant="subtitle1"
        style={{
          marginTop: '20px',
          padding: '10px',
          backgroundColor: '#f4f4f4',
          color: '#333',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}  
      >
        <strong>{selectedSong.name}</strong> - {artists}
      </Typography>

          <Button onClick={clearSelection} variant="outlined" color="error" style={{ margin: '10px',  textAlign: 'center'}}>
            Poista valinta
          </Button>
        </>
      ) : (
        <>
          <TextField
            label="Singan kappalehaku"
            onChange={handleSearchChange}
            fullWidth
            margin="normal"
          />
          <List>
            {songs.map((song) => (
              <ListItem 
                button 
                key={song.id} 
                onClick={() => setSelectedSong(song)}
                selected={selectedSong && selectedSong.id === song.id}
                style={selectedSong && selectedSong.id === song.id ? { backgroundColor: '#f0f0f0' } : {}}
              >
                <ListItemText 
                  primary={song.name} 
                  secondary={song.artists.map((artist, index) => (index > 0 ? ", " + artist.name : artist.name)). join('')}
                />
              </ListItem>
            ))}
          </List>
          {loading && <CircularProgress />}
        </>
      )}

      {selectedSong && (
        <TextField
          label="Karaokelaulajien nimet"
          value={singerName}
          onChange={handleSingerChange}
          fullWidth
          margin="normal"
        />
      )}

      {selectedSong && (
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          Lähetä
        </Button>
      )}
    </form>
    </Paper>
  );
};

export default AddSongView;






