import React, { useState, useEffect } from 'react';
import { fetchSongs } from '../api/api';
import { Grid, ListItemText, Divider, Typography, Chip, Box } from '@mui/material';

const TVView = () => {
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    const getSongs = async () => {
      let fetchedSongs = await fetchSongs();
      fetchedSongs.sort((a, b) => new Date(a[4]) - new Date(b[4]));
      setSongs(fetchedSongs);
    };

    getSongs();
    const intervalId = setInterval(getSongs, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  
  const styles = {
    container: {
        backgroundColor: '#212121',
        color: 'white', 
    },
    listContainer: {
      marginTop: '30px',
      padding: '0x',
      marginLeft: '40px',
      marginRight: '20px',
    },
    headerContainer: {
      width: '100%',
      backgroundColor: '#dae8fc',
      color: 'black',
      padding: '30px',
    },
    listItemStyle: {
      backgroundColor: '#333',
      borderRadius: 8,
      padding: '20px',
      margin: '10px',
    },
    dividerStyle: {
      backgroundColor: 'rgba(255, 255, 255, 0.12)' 
    },
    chipStyle: {
      marginRight: '20px',
      fontSize: '60px',
      height: '80px',
      width: '80px',
      borderRadius: '40px',
      backgroundColor: 'white', 
      color: 'black'
    },
    chipStyleCurrent: {
        marginRight: '20px',
        fontSize: '35px',
        height: '80px',
        width: '80px',
        borderRadius: '40px',
        backgroundColor: 'white', 
        color: 'black'
      }
  };

  if (songs.length === 0) {
    return (
      <div style={styles.listContainer}>
        <Typography variant="h3" style={{ textAlign: 'center', marginBottom: '40px' }}>Ladataan kappaleita...</Typography>
      </div>
    );
  }

  return (<div style={styles.container}>
    <div style={styles.headerContainer}>
    <Typography variant="h1" style={{ textAlign: 'center' }}>Lisää oma biisisi: karaoke.hamis.fi</Typography>
  </div>
    <div style={styles.listContainer}>
      <Grid container spacing={2} flexDirection={'column'} height={'1000px'}>
        {songs.map((song, index) => (
          <div>
            <div style={index === 0 ? { ...styles.listItemStyle, backgroundColor: '#4caf50' } : styles.listItemStyle}>
              <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Chip sx={{'.MuiChip-label': {overflow: 'visible',}}} label={index === 0 ? "NYT" : `${index}`} color="default" style={index === 0 ? styles.chipStyleCurrent : styles.chipStyle} />
                <ListItemText
                  primary={<Typography variant="h3">{`${song[0]} - ${song[1]}`}</Typography>}
                  secondary={<Typography variant="h4">{`Laulaja: ${song[2]}`}</Typography>}
                />
              </Box>
            </div>
            {index < songs.length - 1 && <Divider style={styles.dividerStyle} />}
          </div>
        ))}
      </Grid>
    </div></div>
  );
};

export default TVView;
