import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationToggle from './components/NavigationToggle';
import QueueView from './components/QueueView';
import AddSongView from './components/AddSongView';
import TVView from './components/TVView';
import Admin from './components/Admin';

function App() {
    return (
    <Router>
      <NavigationToggle />
      <Routes>
        <Route path="/add-song" element={<AddSongView />} />
        <Route path="/" element={<QueueView />} />
        <Route path="/tv" element={<TVView />} />
        <Route path="/kirjauduppa-sisaan" element={<Admin />} />
      </Routes>
    </Router>
  );
}

export default App;
