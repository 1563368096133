import React, { useState, useEffect } from 'react';
import { fetchSongs, markAsDone } from '../api/api';
import { List, ListItem, ListItemText, Divider, Typography, Paper, Chip, Box, Button, CircularProgress } from '@mui/material';

function convertSecondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}

function sumSeconds(songs, endIndex) {
  if (endIndex > songs.length) {
      endIndex = songs.length;
  }

  let totalSeconds = 0;

  for (let i = 1; i < endIndex; i++) {
      if (songs[i][6]) {
          totalSeconds += parseInt(songs[i][6]);
      }
  }

  const totalMinutes = Math.round(totalSeconds / 60);

  return totalMinutes;
}

const QueueView = () => {
  const [songs, setSongs] = useState([]);
  const [nextUserSongIndex, setNextUserSongIndex] = useState(null);
  const [userSongs, setUserSongs] = useState([]);

  useEffect(() => {
    fetchSongsAndUpdate();
    const intervalId = setInterval(fetchSongsAndUpdate, 60000);
    const storedUserSongs = JSON.parse(localStorage.getItem('userSongs')) || [];
    setUserSongs(storedUserSongs); 
    return () => clearInterval(intervalId);
  }, []);

  const findNextUserSong = (songs) => {
    const userSongs = JSON.parse(localStorage.getItem('userSongs')) || [];
    const songIndexes = songs.map((song, index) => {
      return userSongs.includes(song[5]) ? index : null;
    }).filter(index => index !== null);

    if (songIndexes.length > 0) {
      setNextUserSongIndex(songIndexes[0]);
    }
  };

  const handleStartSinging = async (songId, singaUrl) => {
    try {
      const responseOk = await markAsDone(songId);
      if (responseOk) {
        window.open(singaUrl, '_blank');
        await fetchSongsAndUpdate();
      } else {
        throw new Error('Failed to remove song');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to remove song');
    }
  };

  const handleRemoveSong = async (songId) => {
    try {
      const responseOk = await markAsDone(songId);
      if (responseOk) {
        await fetchSongsAndUpdate();
      } else {
        throw new Error('Failed to remove song');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to remove song');
    }
  };
  
  const fetchSongsAndUpdate = async () => {
    let fetchedSongs = await fetchSongs();
    fetchedSongs.sort((a, b) => new Date(a[4]) - new Date(b[4]));
    setSongs(fetchedSongs);
    findNextUserSong(fetchedSongs);
  };
  

  if (songs.length === 0) {
    return (
      <Paper style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
         <CircularProgress />
        <Typography variant="h5" style={{ textAlign: 'center', marginBottom: '20px' }}>Ladataan kappaleita...</Typography>
      </Paper>
    );
  }

  return (
    <Paper style={{ margin: '20px', padding: '20px' }}>
      <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>Karaokejono</Typography>
      {localStorage.getItem('hamiskaraoke') ? <>
      <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>
Homma toimii näin:<br/>
1) pyydä laulajia lisäämään kappale itse jonoon menemällä omalla kännykällään osoitteeseen <strong>karaoke.hamis.fi</strong><br/>
2) kun kappaleita on jonossa, voit tällä koneella avata ne Singassa klikkaamalla vihreää nappia. Samalla jono liikkuu.<br/>
3) Kun kappale on ohi, palaa tälle välilehdelle ja klikkaa seuraava kappale soimaan.
        </Typography></> : <></>}
      {nextUserSongIndex ? (
        <Typography style={{ color: 'green', textAlign: 'center', marginBottom: '10px' }}>
          Seuraava oma biisisi on jonossa sijalla {nextUserSongIndex}. Kappaleita jonossa ennen tätä {sumSeconds(songs, nextUserSongIndex)} minuuttia.
        </Typography>
      ): <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>
      Kappaleita jonossa {sumSeconds(songs, songs.length)} minuuttia.
    </Typography>}
      <List>
        {songs.map((song, index) => (
          <React.Fragment key={index}>
            <ListItem style={index === 0 ? { backgroundColor: '#4caf50', color: 'white', borderRadius: 8 } : userSongs.includes(song[5]) ? { backgroundColor: '#dae8fc', borderRadius: 8 } : {}}>
              <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Chip label={index === 0 ? "Nyt" : (index === 1 ? "1" : `${index}`)} color={index <= 1 ? "primary" : "default"} style={{ marginRight: '10px' }} />
                <ListItemText
                  primary={`${song[0]} - ${song[1]}`}
                  secondary={`Laulaja: ${song[2]}`}
                />
              </Box>
              <Chip label={convertSecondsToMinutes(song[6])} color={index <= 1 ? "primary" : "default"} style={{ marginRight: '10px' }} />
              {localStorage.getItem('hamiskaraoke') && index === 1 &&
            <Button
            variant="contained"
            color="success"
            onClick={() => handleStartSinging(songs[0][5], song[7])}
            style={{marginRight: '8px'}}
          >
            Aseta laulettavaksi ja avaa Singassa
          </Button>}
          {localStorage.getItem('hamiskaraoke') && index > 0 ? <Button
            variant="outlined"
            color="error"
            onClick={() => handleRemoveSong(song[5])}
          >
            Poista jonosta
          </Button> : <></>}
            </ListItem>
            {index < songs.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default QueueView;
